import { useQuery } from "@tanstack/react-query";
import { paymentKeys } from "queryKeyFactory";
import { getLatestSubscription, getLatestInvoice } from "services/payments/queries";

export function useLatestSubscription() {
    return useQuery({
        queryKey: paymentKeys.latestSubscription(),
        queryFn: getLatestSubscription,
    });
}
export function useLatestInvoice() {
    return useQuery({
        queryKey: paymentKeys.latestInvoice(),
        queryFn: getLatestInvoice,
    });
}
