import Loader from "components/Common/Loader";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    checkGoogleAuth,
    createWorkflow,
    getWorkflow,
    listComponentDefinitions,
    updateWorkflow,
} from "services/jarvis";
import { IJsonWorkflowSection, IWorkflow, WorkflowState } from "types/jarvis";
import { deepCopy } from "utils";
import { validateWorkflow } from "utils/workflow";
import WorkflowSection from "components/Jarvis/WorkflowSection";
import SnackBar from "components/Common/Snackbar";
import { loginMailBotProfile } from "../../utils/login";

const ASSISTANT = "JARVIS";
const POLLING_INTERVAL_IN_MS = 20000;

const sections: Array<IJsonWorkflowSection> = [
    {
        name: "Workflow Trigger",
        key: "trigger",
    },
    {
        name: "Constraints",
        key: "constraints",
    },
    {
        name: "Actions",
        key: "actions",
    },
];

function Workflow() {
    const [inputQuery, setInputQuery] = useState("");
    const [inputName, setInputName] = useState("");
    const [workflow, setWorkflow] = useState<IWorkflow | undefined>();
    const [fetched, setFetched] = useState(false);
    const [componentDefinitions, setComponentDefinitions] = useState<object[]>([]);
    const [isWorkflowValid, setIsWorkflowValid] = useState(false);
    const timerRef = useRef<NodeJS.Timer>();
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    const [authConfigured, setAuthConfigured] = useState(false);

    const navigate = useNavigate();

    /* Events on creation form */
    const handleInputNameChange = (event) => {
        setInputName(event.target.value);
    };

    const handleInputQueryChange = (event) => {
        setInputQuery(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const workflow: IWorkflow = await createWorkflow({
                assistant: ASSISTANT,
                name: inputName,
                user_query: inputQuery,
            });

            setWorkflow(workflow);
        } catch (error: any) {
            console.error("Error fetching data:", error);
            setErrorMessage("Your workflow could not be generated. Try again");
        }
    };

    useEffect(() => {
        checkAuthConfigured();
    }, []);

    /* Workflow created, start polling its state */
    useEffect(() => {
        if (!workflow || fetched) {
            return;
        }
        fetchWorkflowDetails();
        fetchComponentDefinitions();
        checkAuthConfigured();
        setFetched(true);
    }, [workflow]);

    useEffect(() => {
        if (workflow?.json_workflow && componentDefinitions.length) {
            setIsWorkflowValid(validateWorkflow(workflow.json_workflow, componentDefinitions));
        }
    }, [workflow, componentDefinitions]);

    const fetchWorkflowDetails = async () => {
        if (!workflow || !workflow?.id) {
            return;
        }
        try {
            const data: IWorkflow = await getWorkflow(workflow.id);
            setWorkflow(data);
            if (data.state === WorkflowState.Initiated || data.state === WorkflowState.Processing) {
                const timerId = setTimeout(fetchWorkflowDetails, POLLING_INTERVAL_IN_MS);
                timerRef.current = timerId;
            }
        } catch (err) {
            console.error(err);
        }
    };

    const checkAuthConfigured = async () => {
        const data = await checkGoogleAuth();
        setAuthConfigured(data.auth_creds_exist);
    };

    const fetchComponentDefinitions = async () => {
        try {
            const data: object[] = await listComponentDefinitions();
            setComponentDefinitions(data);
        } catch (err) {
            console.error(err);
        }
    };

    /* Save updated steps of workflow, and activate it */
    const applyWorkflow = async () => {
        if (!workflow || !workflow?.id || !workflow?.json_workflow) {
            return;
        }
        try {
            await updateWorkflow(workflow.id, { json_workflow: workflow.json_workflow, is_active: true });
            navigate("/workflows/list");
        } catch (err) {
            setErrorMessage("Workflow could not be applied for your email.");
        }
    };

    /* Renders steps of workflow */
    const renderSection = (section: IJsonWorkflowSection) => {
        if (!workflow?.json_workflow) {
            return;
        }
        let workflowSectionJson = workflow.json_workflow[section.key];
        workflowSectionJson = Array.isArray(workflowSectionJson) ? workflowSectionJson : [workflowSectionJson];
        return (
            <div className="mb-5">
                <div className="font-medium underline">{section.name}:</div>
                <WorkflowSection
                    sectionKey={section.key}
                    jsonObject={workflowSectionJson}
                    setJsonObject={setUpdatedWorkflow}
                    componentDefinitions={componentDefinitions}
                />
            </div>
        );
    };

    const setUpdatedWorkflow = (section: string, updatedEntities: object) => {
        if (!workflow) return;
        const updatedWorkflow = deepCopy(workflow);

        if (section === "trigger") {
            updatedEntities = updatedEntities[0];
        }
        if (updatedWorkflow.json_workflow) {
            updatedWorkflow.json_workflow[section] = updatedEntities;
        }
        setWorkflow(updatedWorkflow);
    };

    const handleRetry = (event) => {
        setFetched(false);
        handleSubmit(event);
    };

    const isInputInvalid = inputQuery.length === 0 || inputName.length === 0;
    return (
        <>
            {!authConfigured && (
                <div className="border border-black p-6 rounded-lg">
                    <div className="font-medium pb-3">Incremental auth for Jarvis</div>
                    <img
                        className="cursor-pointer"
                        src="/images/btn_google_signin_light_normal_web_short.png"
                        alt="Authorize in with google"
                        onClick={() =>
                            window.open(
                                `${process.env.REACT_APP_BACKEND_API_BASE_URL}/jarvis/google-auth/login/`,
                                "_self",
                            )
                        }
                    />
                </div>
            )}
            {authConfigured && (
                <div className="border border-black p-6 rounded-lg">
                    <div className="font-medium pb-3">Create a new workflow</div>
                    <form onSubmit={handleSubmit}>
                        <div className="flex flex-col">
                            <div className="flex flex-row">
                                <input
                                    type="text"
                                    placeholder="Workflow name"
                                    value={inputName}
                                    onChange={handleInputNameChange}
                                    className="w-full md:w-3/4 lg:w-1/2 p-2 rounded-md border border-gray-800 mb-3 bg-[#EFF0F6]"
                                />
                                <div className="-mt-3 ml-1 font-medium text-lg">*</div>
                            </div>
                            <input
                                type="text"
                                placeholder="When I get an email from x, move it to label Y"
                                value={inputQuery}
                                onChange={handleInputQueryChange}
                                className="w-full p-2 rounded-md border border-gray-800 mb-3 bg-[#EFF0F6]"
                            />

                            {!workflow && (
                                <button
                                    type="submit"
                                    className={`rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm ${
                                        isInputInvalid
                                            ? "bg-indigo-400"
                                            : "bg-indigo-600 hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                    } ml-auto`}
                                    disabled={isInputInvalid}
                                >
                                    Generate
                                </button>
                            )}
                        </div>
                    </form>
                    {!workflow && (
                        <>
                            <div className="mt-8 flex flex-col">
                                <div className="font-medium">Available Constraints and Actions:</div>
                                <br />
                                <div className="font-medium">Constraints</div>
                                <ol className="list-decimal pl-6">
                                    <li>Subject contains a particular keyword</li>
                                    <li>Body contains a particular keyword</li>
                                    <li>Subject or body contain a particular keyword</li>
                                    <li>Sender email is</li>
                                    <li>Sender domain is</li>
                                    <li>Email has attachment or not</li>
                                    <li>Email is received before/after a particular date time</li>
                                    <li>My email is in "To"/"CC"/"BCC" field</li>
                                </ol>
                                <br />
                                <div className="font-medium">Actions</div>
                                <ol className="list-decimal pl-6">
                                    <li>Add/Update label</li>
                                    <li>Archive email</li>
                                    <li>Mark email as read</li>
                                    <li>Create a digest</li>
                                </ol>
                            </div>
                        </>
                    )}

                    {workflow && (
                        <>
                            {(workflow.state === WorkflowState.Initiated ||
                                workflow.state === WorkflowState.Processing) && (
                                <div className="flex items-center justify-center">
                                    <div className="mr-2">Generating your workflow</div>
                                    <Loader fullScreen={false} size={4} />
                                </div>
                            )}
                            {workflow.state === WorkflowState.Configured && (
                                <div>
                                    <div className="flex items-center justify-center">
                                        <div className="mr-2">Workflow generated</div>
                                    </div>
                                    {workflow.json_workflow && Object.keys(workflow.json_workflow).length !== 0 && (
                                        <div className="flex mt-6 flex-col">
                                            <div className="mr-2 font-medium">Steps of your workflow</div>
                                            <div className="flex flex-col border border-black rounded-md py-2 px-4 bg-[#EFF0F6]">
                                                {sections.map((section) => renderSection(section))}
                                                <div className="flex flex-row mt-4 mb-2">
                                                    <button
                                                        type="submit"
                                                        className={`${
                                                            isWorkflowValid
                                                                ? "bg-indigo-600 hover:bg-indigo-500 cursor-pointer"
                                                                : "bg-indigo-300 cursor-default"
                                                        } rounded-md px-6 py-3 text-sm font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600`}
                                                        onClick={applyWorkflow}
                                                        disabled={!isWorkflowValid}
                                                    >
                                                        Apply to your email
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}

                            {workflow.state === WorkflowState.Failed && (
                                <div className="flex items-center justify-center">
                                    <div className="mr-4">Workflow generation failed</div>
                                    <button
                                        type="button"
                                        className="rounded-md bg-indigo-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                                        onClick={handleRetry}
                                    >
                                        Try again
                                    </button>
                                </div>
                            )}
                        </>
                    )}
                    <SnackBar
                        open={successMessage.length > 0}
                        handleClose={() => setSuccessMessage("")}
                        severity="success"
                        message={successMessage}
                        autoHideDuration={5000}
                    />
                    <SnackBar
                        open={errorMessage.length > 0}
                        handleClose={() => setErrorMessage("")}
                        severity="error"
                        message={errorMessage}
                        autoHideDuration={5000}
                    />
                </div>
            )}
        </>
    );
}

export default Workflow;
