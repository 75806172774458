import { IPricing } from "types/payments";

export function getPricingDetails(price: IPricing) {
    let priceDescription: string = "",
        unsubscribeLimit: string = "",
        bulkDeleteLimit: string = "",
        priceDisplayName: string = price.nickname;
    if (price.nickname === "freebie") {
        priceDescription = "Access to premium feature for a week.";
        unsubscribeLimit = "10 senders";
        bulkDeleteLimit = "10 senders";
    } else if (price.nickname === "basic") {
        priceDescription = "Monthly subscription with actions for upto 50 senders.";
        unsubscribeLimit = "50 senders";
        bulkDeleteLimit = "50 senders";
    } else if (price.nickname === "power") {
        priceDescription = "Annual plan with unlimited actions.";
        unsubscribeLimit = "Unlimited";
        bulkDeleteLimit = "Unlimited";
    } else if (price.nickname === "mailbot_monthly") {
        priceDescription = "Monthly plan with unlimited actions.";
        priceDisplayName = "Monthly Plan";
    } else if (price.nickname === "mailbot_annual") {
        priceDescription = "Annual plan with unlimited actions.";
        priceDisplayName = "Annual Plan";
    } else if (price.nickname === "power_v2") {
        priceDisplayName = "Power";
        priceDescription = "Annual plan with unlimited actions.";
        unsubscribeLimit = "Unlimited";
        bulkDeleteLimit = "Unlimited";
    }
    return { priceDescription, unsubscribeLimit, bulkDeleteLimit, priceDisplayName };
}
