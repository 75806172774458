import { Box } from "@mui/material";
import Loader from "components/Common/Loader";
import SnackBar from "components/Common/Snackbar";
import PricingCatalog from "components/Subscription/PricingCatalog";
import SubscriptionCancellation from "components/Subscription/SubscriptionCancellation";
import { maxContentWidthDesktop } from "defines";
import { useRenewSubscription } from "hooks/api/payments/mutations";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useState } from "react";

export default function Subscription() {
    const { data: subscription, isPending } = useLatestSubscription();
    const renewSubscription = useRenewSubscription();
    const [showCancellation, setShowCancellation] = useState(false);
    // const { data: showNewPricingPlans, isPending: showNewPricingPlansPending } = useShowNewPricingPlans();
    const [renewSubscriptionSuccessMessage, setRenewSubscriptionSuccessMessage] = useState<string>("");
    const [renewSubscriptionErrorMessage, setRenewSubscriptionErrorMessage] = useState<string>("");

    // Handle renewal of subscription
    const handleRenew = () => {
        renewSubscription.mutate(subscription!.id, {
            onSuccess: () => {
                setRenewSubscriptionSuccessMessage("Subscription renewed successfully");
            },
            onError: () => {
                setRenewSubscriptionErrorMessage("Error occured while renewing your subscription. Please try again.");
            },
        });
    };

    if (isPending) {
        return <Loader />;
    }
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            gap={"10px"}
            maxWidth={maxContentWidthDesktop}
            margin={"auto"}
            p={2}
        >
            <Box display={"flex"} flexDirection={"column"} alignItems={"center"} width={"100%"}>
                <PricingCatalog handleCancel={() => setShowCancellation(true)} handleRenew={handleRenew} />
            </Box>
            <SubscriptionCancellation
                subscription={subscription!}
                open={showCancellation}
                setOpen={setShowCancellation}
            />
            {renewSubscription.isPending && <Loader />}
            <SnackBar
                open={!!renewSubscriptionSuccessMessage}
                handleClose={() => setRenewSubscriptionSuccessMessage("")}
                severity={"success"}
                message={renewSubscriptionSuccessMessage}
            />
            <SnackBar
                open={!!renewSubscriptionErrorMessage}
                handleClose={() => setRenewSubscriptionErrorMessage("")}
                severity={"error"}
                message={renewSubscriptionErrorMessage}
            />
        </Box>
    );
}
