import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { Box, Button, Paper, Typography } from "@mui/material";
import Loader from "components/Common/Loader";
import { SubscriptionStatus } from "defines";
import { useCreateCheckoutSession } from "hooks/api/applications";
import { usePricingCatalogue } from "hooks/api/mailbot/queries";
import {
    useCustomerPortalSession,
    useScheduleUpgradeSubscription,
    useUpgradeSubscription,
} from "hooks/api/payments/mutations";
import { useLatestSubscription } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import moment from "moment-timezone";
import { IPricing, ISubscription } from "types/payments";
import { getPricingDetails } from "utils/prices";
import PriceFeatureList from "./PriceFeatureList";
import PriceHeading from "./PriceHeading";
import PriceSubHeading from "./PriceSubHeading";
import PriceTitleAndDescription from "./PriceTitleAndDescription";

function CurrentPlanBadge() {
    return (
        <Box
            sx={{
                width: "116px",
                height: "30px",
                background: "#0D0E23",
                color: "white",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "2px",
            }}
        >
            <Typography align="center">Current Plan</Typography>
        </Box>
    );
}

function FreebieEndingWarning({ subscription }: { subscription: ISubscription }) {
    return (
        <Box display={"flex"} marginTop={"16px"} gap={"8px"} ml={"16px"}>
            <ReportGmailerrorredIcon
                sx={{
                    color: "#900B09",
                    fontSize: "30px",
                }}
            />
            <Box>
                <Typography variant="body2" sx={{ color: "#B3261E" }}>
                    Free subscription is ending on
                </Typography>
                <Typography fontWeight={700} variant="body2" sx={{ color: "#B3261E" }}>
                    {new Date(subscription?.current_period_end || "").toLocaleDateString("en-GB", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                    })}
                </Typography>
            </Box>
        </Box>
    );
}

const PricingCard = ({
    price,
    subscription,
    index,
}: {
    price: IPricing;
    subscription: ISubscription | undefined;
    index: number;
}) => {
    const upgradeSubscription = useUpgradeSubscription();
    const createCheckoutSessionMutation = useCreateCheckoutSession();
    const scheduleUpgradeSubscription = useScheduleUpgradeSubscription();
    const { md } = useCustomMediaQuery();
    // Handle payments for the selected subscription plan
    const isSubscriptionActive =
        !!subscription &&
        [SubscriptionStatus.STATUS_ACTIVE, SubscriptionStatus.STATUS_PAST_DUE].includes(subscription.status);
    const handleCheckout = (data) => {
        createCheckoutSessionMutation.mutate(
            { data },
            {
                onSuccess: (checkoutSession) => {
                    window.location = checkoutSession.redirect_url;
                },
            },
        );
    };
    const onPricingPlanCardClick = ({ price }) => {
        if (isSubscriptionActive) {
            if (price.nickname === "mailbot_annual") {
                scheduleUpgradeSubscription.mutate({ id: subscription.id, newPriceId: price.id });
            }
            upgradeSubscription.mutate(
                { id: subscription.id, newPriceId: price.id, couponId: price.coupon?.id },
                {
                    onSuccess: (checkoutSession) => {
                        window.location = checkoutSession.subscription_update_confirm_url;
                    },
                },
            );
        } else {
            handleCheckout({
                price: price.id,
                coupon: price.coupon?.id,
            });
        }
    };
    let theme1 = {
        priceDescriptionColor: "#64748B",
        backgroundColor: "linear-gradient(180.69deg, #E2E5FD -130.74%, #FFFFFF 74.9%)",
        buttonBackground: "transparent",
        buttonTextColor: "#FF9600",
        buttonVariant: "outlined" as any,
        buttonBorderWidth: "2px",
    };
    let theme2 = {
        priceDescriptionColor: "#0D0E23",
        backgroundColor: "#EFBA42",
        buttonBackground: "#0D0E23",
        buttonTextColor: "white",
        buttonVariant: "contained" as any,
        buttonBorderWidth: "0px",
    };
    const { priceDescription, unsubscribeLimit, bulkDeleteLimit, priceDisplayName } = getPricingDetails(price);
    const currentPriceSubscribed = price.nickname === subscription?.price.nickname;
    let currentPriceToBeTransitioned = false;
    if (subscription?.phases && subscription.phases.length > 0) {
        const lastPhase = subscription.phases[subscription.phases.length - 1]["items"][0];
        currentPriceToBeTransitioned = price.id === lastPhase.price;
    }
    return (
        <>
            <Paper
                sx={{
                    background: index % 2 === 0 ? theme1.backgroundColor : theme2.backgroundColor,
                    maxWidth: md ? "290px" : "343px",
                    width: "100%",
                    minHeight: md ? "478px" : "324px",
                }}
            >
                {price.is_current_plan && (
                    <Box display={"flex"} justifyContent={md ? "flex-start" : "flex-end"}>
                        <CurrentPlanBadge />
                    </Box>
                )}
                {currentPriceSubscribed && price.nickname === "freebie" && (
                    <FreebieEndingWarning subscription={subscription} />
                )}
                <Box
                    sx={{
                        px: "24px",
                        pt: price.is_current_plan ? "10px" : md ? "40px" : "20px",
                        pb: "40px",
                    }}
                >
                    <PriceTitleAndDescription
                        priceDescription={priceDescription}
                        priceDescriptionColor={
                            index % 2 === 0 ? theme1.priceDescriptionColor : theme2.priceDescriptionColor
                        }
                        priceDisplayName={priceDisplayName}
                    />
                    <Box display={"flex"} alignItems={"center"} gap={"10px"} marginBottom={md ? "24px" : "12px"}>
                        <PriceHeading price={price} />
                    </Box>
                    <PriceSubHeading price={price} />
                    {!currentPriceSubscribed && currentPriceToBeTransitioned && (
                        <Box>
                            <Typography variant="body2">
                                Price will be active from{" "}
                                <Typography display={"inline"} component={"span"} variant="body2" fontWeight={700}>
                                    {moment(subscription?.current_period_end).format("DD MMMM YYYY")}
                                </Typography>
                            </Typography>
                        </Box>
                    )}
                    {!currentPriceSubscribed && !currentPriceToBeTransitioned && (
                        <Button
                            disableElevation
                            fullWidth
                            disableRipple
                            data-testid={`pricing-card-${price.nickname}`}
                            variant={index % 2 === 0 ? theme1.buttonVariant : theme2.buttonVariant}
                            sx={{
                                background: index % 2 === 0 ? theme1.buttonBackground : theme2.buttonBackground,
                                color: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                                fontWeight: 600,
                                borderWidth: index % 2 === 0 ? theme1.buttonBorderWidth : theme2.buttonBorderWidth,
                                borderColor: index % 2 === 0 ? theme1.buttonTextColor : theme2.buttonTextColor,
                                marginBottom: "24px",
                            }}
                            onClick={() => {
                                onPricingPlanCardClick({ price });
                            }}
                        >
                            Subscribe
                        </Button>
                    )}
                    <PriceFeatureList unsubscribeLimit={unsubscribeLimit} bulkDeleteLimit={bulkDeleteLimit} />
                </Box>
            </Paper>
            {(upgradeSubscription.isPending || createCheckoutSessionMutation.isPending) && <Loader />}
        </>
    );
};

const allowedPricingNames = ["freebie", "basic", "power", "mailbot_monthly", "mailbot_annual", "power_v2"];

function ManagePaymentAndSubscription({ subscription, handleCancel, handleRenew }) {
    const customerPortalSessionMutation = useCustomerPortalSession();
    const { md } = useCustomMediaQuery();
    let subscriptionEndDate = new Date(subscription?.current_period_end || "").toLocaleDateString("en-GB", {
        day: "numeric",
        month: "long",
        year: "numeric",
    });
    let subscriptionData = {} as any;
    if (subscription?.cancel_at_period_end) {
        subscriptionData = {
            text: "Renew subscription",
            action: handleRenew,
            color: "green",
            daysRemainingMessage: "Subscription will expire on",
        };
    } else if (subscription?.status === SubscriptionStatus.STATUS_ACTIVE) {
        subscriptionData = {
            text: "Cancel Subscription",
            action: handleCancel,
            color: "#FF3B30",
            daysRemainingMessage: subscription?.cancel_at ? "Subscription ending on" : "Next billing date on",
        };
    } else if (SubscriptionStatus.STATUS_PAST_DUE === subscription.status) {
        subscriptionData = {
            daysRemainingMessage: "You will loose access if not paid till",
        };
        subscriptionEndDate = moment(subscription?.current_period_start).add(7, "days").format("DD MMMM YYYY");
    }
    return (
        <Box
            sx={{
                border: "1px solid #FF9600",
                background: "#FF96001A",
                borderRadius: "4px",
                p: 2,
                marginTop: "32px",
                display: "flex",
                flexDirection: md ? "row" : "column",
                justifyContent: "space-between",
                alignItems: md ? "center" : "flex-start",
                width: "100%",
            }}
        >
            <Box>
                <Typography gutterBottom fontWeight={600} fontSize={"20px"}>
                    Payment and subscription
                </Typography>
                <Typography fontSize={"14px"}>
                    {subscriptionData.daysRemainingMessage}: <strong>{subscriptionEndDate}</strong>
                </Typography>
            </Box>
            <Box
                sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    width: "100%",
                    maxWidth: md ? "240px" : "295px",
                    marginTop: md ? 0 : "32px",
                }}
            >
                <Box
                    sx={{
                        width: "100%",
                    }}
                >
                    <Button
                        disableElevation
                        disableRipple
                        variant="contained"
                        fullWidth
                        onClick={() => {
                            customerPortalSessionMutation.mutate(undefined, {
                                onSuccess: (data) => {
                                    window.location = data.customer_portal_url;
                                },
                            });
                        }}
                        data-testid="manage-billing-button"
                        sx={{
                            background: "#0D0E23",
                            color: "white",
                            fontWeight: 600,
                            marginBottom: "10px",
                        }}
                    >
                        Manage Payment
                    </Button>
                </Box>
                {subscriptionData.action && (
                    <Box
                        sx={{
                            width: "100%",
                        }}
                    >
                        <Button
                            disableElevation
                            disableRipple
                            fullWidth
                            data-testid="subscription-action-button"
                            onClick={subscriptionData.action}
                            sx={{
                                color: subscriptionData.color,
                            }}
                            variant="text"
                        >
                            {subscriptionData.text}
                        </Button>
                    </Box>
                )}
            </Box>
        </Box>
    );
}

export default function PricingCatalog({ handleCancel, handleRenew }) {
    const { data: subscription, isPending: subscriptionPending } = useLatestSubscription();
    const { data: pricings, isPending: pricesPending } = usePricingCatalogue();
    const { md } = useCustomMediaQuery();
    if (subscriptionPending || pricesPending) {
        return <Loader />;
    }
    return (
        <Box maxWidth={md ? "916px" : "343px"} width={"100%"}>
            <Box marginBottom={"32px"}>
                <Typography fontSize={md ? "20px" : "14px"} fontWeight={600} sx={{ color: "#2B333B" }}>
                    EmailZap Subscription
                </Typography>
                <Typography variant={md ? "body2" : "caption"}>
                    Choose your right plan for seamless email experience.
                </Typography>
            </Box>
            <Box display={"flex"} gap={"24px"} flexDirection={md ? "row" : "column"} justifyContent={"center"}>
                {pricings
                    .filter((p) => allowedPricingNames.includes(p.nickname))
                    .map((pricing, idx) => (
                        <PricingCard key={pricing.id} price={pricing} subscription={subscription} index={idx} />
                    ))}
            </Box>
            {!!subscription && (
                <ManagePaymentAndSubscription
                    handleCancel={handleCancel}
                    handleRenew={handleRenew}
                    subscription={subscription}
                />
            )}
        </Box>
    );
}
