import { Box, capitalize, Typography } from "@mui/material";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
export default function PriceTitleAndDescription({ priceDisplayName, priceDescription, priceDescriptionColor }) {
    const { md } = useCustomMediaQuery();
    return (
        <Box>
            <Typography gutterBottom fontSize={md ? "22px" : "20px"} fontWeight={700} sx={{ color: "#0D0E23" }}>
                {capitalize(priceDisplayName)}
            </Typography>
            <Typography
                variant={md ? "body1" : "body2"}
                sx={{
                    color: priceDescriptionColor,
                }}
            >
                {priceDescription}
            </Typography>
        </Box>
    );
}
