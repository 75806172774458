import { capitalize, Typography } from "@mui/material";
import { useLatestInvoice } from "hooks/api/payments/queries";
import { useCustomMediaQuery } from "hooks/useMediaQuery";
import { IInvoice, IPricing } from "types/payments";

function getPriceForHeading(price: IPricing, latestInvoice: IInvoice | undefined) {
    const coupon = price.coupon;
    if (coupon) {
        return price.final_amount / 100;
    } else if (price.id === latestInvoice?.price && latestInvoice.coupon) {
        return latestInvoice.amount_paid / 100;
    } else {
        return price.unit_amount / 100;
    }
}

function getIntervalForHeading(price: IPricing, latestInvoice) {
    const coupon = price.coupon;
    if (coupon) {
        if (coupon.duration === "forever") {
            return `/ ${capitalize(price.interval)}`;
        } else if (coupon.duration === "repeating") {
            return `for first ${coupon.duration_in_months} ${coupon.duration_in_months! > 1 ? "months" : "month"}`;
        } else {
            return `for first ${price.interval}`;
        }
    } else if (price.id === latestInvoice?.price && latestInvoice.coupon) {
        return `for first ${price.interval}`;
    } else {
        if (price.nickname === "freebie") {
            return `for 7 days`;
        } else {
            return `/ ${capitalize(price.interval)}`;
        }
    }
}

export default function PriceHeading({
    price,
    headingFontSizeMedium = "56px",
    headingFontSizeSmall = "44px",
}: {
    price: IPricing;
    headingFontSizeMedium?: string;
    headingFontSizeSmall?: string;
}) {
    const { md } = useCustomMediaQuery();
    const { data: latestInvoice, isPending } = useLatestInvoice();
    if (isPending) {
        return null;
    }
    const priceForHeading = getPriceForHeading(price, latestInvoice);
    const intervalForHeading = getIntervalForHeading(price, latestInvoice);
    return (
        <>
            <Typography fontWeight={600} fontSize={md ? headingFontSizeMedium : headingFontSizeSmall}>
                ${priceForHeading}
            </Typography>
            <Typography fontWeight={300}>{intervalForHeading}</Typography>
        </>
    );
}
