import { backendServerAxiosInstance } from "services";
import { IInvoice, ISubscription } from "types/payments";

export const getLatestSubscription: () => Promise<ISubscription> = async () => {
    const response = await backendServerAxiosInstance.get("/payments/subscriptions/latest/");
    if (response.status === 204) {
        // Returning no subscription is a valid state
        return null;
    }
    return response.data;
};

export const getLatestInvoice: () => Promise<IInvoice> = async () => {
    const response = await backendServerAxiosInstance.get("/payments/invoices/latest/");
    return response.data;
};
