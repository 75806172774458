import { Box, Tooltip, Typography } from "@mui/material";
import { GridColDef } from "@mui/x-data-grid";
import { useMutationState } from "@tanstack/react-query";
import DataTable from "components/Common/Data/DataTable";
import SubscriptionLimitDialog from "components/Common/Dialog/SubscriptionLimitDialog";
import EmailDomainImage from "components/Common/EmailDomainImage";
import Loader from "components/Common/Loader";
import { UserAction, UserActionReason } from "defines";
import { useDeleteHistoricalEmails } from "hooks/api/mailbot/mutations";
import { useBulkDeleteProfiles } from "hooks/api/mailbot/paginatedQueries";
import { SnackbarMessageContext } from "layouts/AppLayout";
import moment from "moment-timezone";
import { mailbotKeys } from "queryKeyFactory";
import { useContext, useState } from "react";
import { getDisplayNumber } from "utils/formatter";
import FuturePreferenceButton from "./FuturePreferenceButton";

function CustomRuleCell({ profile, setSubscriptionLimitDialogOpen }) {
    const { setSuccessMessage, setSuccessMessageTitle } = useContext(SnackbarMessageContext);
    const deleteEmailsMutation = useDeleteHistoricalEmails();
    const handleDeleteEmails = ({ userAction }) => {
        deleteEmailsMutation.mutate(
            {
                senderProfileId: profile.id,
                action: userAction,
            },
            {
                onSuccess: () => {
                    let label;
                    if (userAction === UserAction.MOVE_TO_TRASH) {
                        label = "moved to trash";
                    } else if (userAction === UserAction.ARCHIVE) {
                        label = "archived";
                    } else {
                        label = "moved to inbox";
                    }
                    if (profile.user_action_reason !== UserActionReason.MARKED_DELETED) {
                        setSuccessMessageTitle("Deleted successfully");
                        setSuccessMessage(`Email deleted successfully and all future emails will be ${label}`);
                    }
                },
                onError: (e: any) => {
                    if (e.status === 402) {
                        setSubscriptionLimitDialogOpen(true);
                    }
                },
            },
        );
    };
    if (deleteEmailsMutation.isPending) {
        return null;
    } else {
        return <FuturePreferenceButton profile={profile} handleDeleteEmails={handleDeleteEmails} />;
    }
}

const filters = [
    {
        label: "Auto Delete",
        condition: [
            { user_action: UserAction.MOVE_TO_TRASH },
            {
                user_action_reason: UserActionReason.MARKED_DELETED,
            },
        ],
    },
    {
        label: "Auto Archive",
        condition: [
            { user_action: UserAction.ARCHIVE },
            {
                user_action_reason: UserActionReason.MARKED_DELETED,
            },
        ],
    },
    {
        label: "Auto Inbox",
        condition: [
            { user_action: UserAction.MOVE_TO_INBOX },
            {
                user_action_reason: UserActionReason.MARKED_DELETED,
            },
        ],
    },
];

export default function DeleteEmailsTable() {
    const profilesDeleting = useMutationState({
        filters: { mutationKey: mailbotKeys.deleteHistoricalEmails(), status: "pending" },
        select: (mutation: any) => mutation.state.variables.senderProfileId,
    });
    const [subscriptionLimitDialogOpen, setSubscriptionLimitDialogOpen] = useState(false);
    const columns: GridColDef[] = [
        {
            field: "sender_email",
            headerName: "Sender's Email",
            flex: 2,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            renderCell: (params) => {
                return (
                    <Box display={"flex"} alignItems={"center"} gap={"16px"} height={"100%"}>
                        <EmailDomainImage
                            email={params.row.sender_email}
                            domain={params.row.sender_domain}
                            name={params.row.sender_name}
                        />
                        <Tooltip title={params.value}>
                            <Typography noWrap>{params.value}</Typography>
                        </Tooltip>
                    </Box>
                );
            },
        },
        {
            field: "scanned_count", // We have to include the field if we are sorting according to it. We will make it not visible.
        },
        {
            field: "inbox_count",
            headerName: "Emails Received",
            flex: 1,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            renderCell: (params) => {
                if (params.row.user_action_reason === UserActionReason.MARKED_DELETED) {
                    return null;
                } else if (profilesDeleting.includes(params.row.id)) {
                    return (
                        <Box display="flex" alignItems="center" gap={"8px"} height={"100%"}>
                            <Loader size={20} fullScreen={false} />
                            <Typography fontSize={"14px"} lineHeight={"16.94px"} color="#000000" fontWeight={500}>
                                Deleting mails...
                            </Typography>
                        </Box>
                    );
                } else {
                    return (
                        <Box display="flex" alignItems="center" height={"100%"}>
                            <Typography>{getDisplayNumber(params.value)}</Typography>
                        </Box>
                    );
                }
            },
        },
        {
            field: "recent_timestamp",
            headerName: "Last Email Received",
            flex: 1,
            sortingOrder: ["asc", "desc"],
            headerClassName: "bg-white",
            renderCell: (params) => {
                if (
                    profilesDeleting.includes(params.row.id) ||
                    params.row.user_action_reason === UserActionReason.MARKED_DELETED
                ) {
                    return null;
                }
                return moment(params.value).format("MMM DD, YYYY");
            },
        },
        {
            field: "custom_rules",
            headerName: "",
            flex: 2,
            sortable: false,
            disableColumnMenu: true,
            headerClassName: "bg-white",
            resizable: false,
            align: "right",
            renderCell: (params) => (
                <CustomRuleCell profile={params.row} setSubscriptionLimitDialogOpen={setSubscriptionLimitDialogOpen} />
            ),
        },
    ];
    return (
        <>
            <SubscriptionLimitDialog
                open={subscriptionLimitDialogOpen}
                setOpen={setSubscriptionLimitDialogOpen}
                variant="delete"
            />
            <DataTable
                defaultOrdering={[{ field: "scanned_count", sort: "desc" }]}
                tabs={[
                    {
                        label: "All Mails",
                        condition: [],
                        filters: [],
                        columnsVisibilityModel: {
                            scanned_count: false,
                        },
                    },
                    {
                        label: "Deleted",
                        condition: [{ user_action_reason: UserActionReason.MARKED_DELETED }],
                        filters,
                        columnsVisibilityModel: {
                            inbox_count: false,
                            scanned_count: false,
                            recent_timestamp: false,
                        },
                        badgeContent: (data) => {
                            return data.deleted_count;
                        },
                    },
                ]}
                columns={columns}
                useData={useBulkDeleteProfiles}
                pageSize={20}
            />
        </>
    );
}
