export type OrderDirection = "asc" | "desc";

export enum WorkflowState {
    Initiated = "initiated",
    Processing = "processing",
    Configured = "configured",
    Failed = "failed",
}

export interface IWorkflow {
    id: number;
    assistant?: string;
    user_query?: string;
    state: string;
    json_workflow?: IJsonWorkflow | null;
    is_active?: boolean;
    created?: string;
    name: string;
}

export interface IJsonWorkflow {
    actions: IJsonWorkflowAction[];
    constraints: IJsonWorkflowConstraint[][];
    trigger: IJsonWorkflowTrigger;
}

export interface IJsonWorkflowTrigger {
    tag: string;
    params: object;
}

export interface IJsonWorkflowAction {
    tag: string;
    params: object;
}

export interface IJsonWorkflowConstraint {
    tag: string;
    params: object;
    description: string;
}

export interface IJsonWorkflowSection {
    name: string;
    key: "trigger" | "actions" | "constraints";
}
