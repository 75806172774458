import { Box, Typography } from "@mui/material";
import CheckIcon from "components/Common/Icons/CheckIcon";
import { useCustomMediaQuery } from "hooks/useMediaQuery";

export default function PriceFeatureList({
    unsubscribeLimit,
    bulkDeleteLimit,
}: {
    unsubscribeLimit: string;
    bulkDeleteLimit: string;
}) {
    const { md } = useCustomMediaQuery();
    return (
        <Box marginTop={"16px"} display={"flex"} flexDirection={"column"} gap={"12px"}>
            <Box display={"flex"} gap={"17px"}>
                <Box>
                    <CheckIcon />
                </Box>
                <Typography variant={md ? "body1" : "body2"} fontWeight={500}>
                    Auto-Cleaner
                </Typography>
            </Box>
            <Box display={"flex"} gap={"17px"}>
                <Box>
                    <CheckIcon />
                </Box>
                <Typography fontWeight={500} variant={md ? "body1" : "body2"}>
                    Bulk Delete{" "}
                    {bulkDeleteLimit.length > 0 && (
                        <Typography
                            fontWeight={500}
                            component={"span"}
                            display={"inline"}
                            sx={{ color: "#4B5768" }}
                            variant={md ? "body1" : "body2"}
                        >
                            ({bulkDeleteLimit})
                        </Typography>
                    )}
                </Typography>
            </Box>
            <Box display={"flex"} gap={"17px"} alignItems={"center"}>
                <Box>
                    <CheckIcon />
                </Box>
                <Typography lineHeight={"19.36px"} fontWeight={500} variant={md ? "body1" : "body2"}>
                    Bulk Unsubscribe{" "}
                    {unsubscribeLimit.length > 0 && (
                        <Typography
                            variant={md ? "body1" : "body2"}
                            fontWeight={500}
                            lineHeight={"19.36px"}
                            display={"inline"}
                            component={"span"}
                            sx={{ color: "#4B5768" }}
                        >
                            ({unsubscribeLimit})
                        </Typography>
                    )}
                </Typography>
            </Box>
        </Box>
    );
}
