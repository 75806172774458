import { usePostHog } from "posthog-js/react";
import { useEffect } from "react";

const CURRENT_ENV = process.env.REACT_APP_NODE_ENV;

const PostHogPageviewTracker = () => {
    const posthog = usePostHog();
    useEffect(() => {
        if (CURRENT_ENV === "prod" && posthog) {
            posthog.capture("$pageview");
        }
    }, [posthog]);

    return null;
};

export default PostHogPageviewTracker;
