import { capitalize, Typography } from "@mui/material";
import { useLatestInvoice } from "hooks/api/payments/queries";
import { IPricing } from "types/payments";
export default function PriceSubHeading({ price }: { price: IPricing }) {
    const { data: latestInvoice, isPending } = useLatestInvoice();
    if (isPending) {
        return null;
    }
    if (price.nickname === "mailbot_annual") {
        return (
            <Typography gutterBottom>
                <s>$60/year</s> (Get 40% off)
            </Typography>
        );
    } else if (price.coupon?.duration === "forever") {
        return (
            <Typography gutterBottom>
                <s>
                    ${price.unit_amount / 100}
                    {`/${capitalize(price.interval)} `}
                </s>
                (Get {price.coupon.percent_off}% off)
            </Typography>
        );
    } else if (price.coupon?.duration === "repeating") {
        return (
            <Typography gutterBottom>
                ${price.unit_amount / 100} per {price.interval} afterwards
            </Typography>
        );
    } else if (price.coupon?.duration === "once") {
        return (
            <Typography gutterBottom>
                ${price.unit_amount / 100} per {price.interval} afterwards
            </Typography>
        );
    } else if (price.id === latestInvoice?.price && latestInvoice.coupon) {
        return (
            <Typography gutterBottom>
                ${price.unit_amount / 100} per {price.interval} afterwards
            </Typography>
        );
    } else {
        return null;
    }
}
